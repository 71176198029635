<div class="intro">
  <div class="nav-extended nav-full-header">
    <div class="nav-background">
      <video autoplay="true" loop="true" id="videoBackground" muted="true" playsinline="true">
        <!-- <source id="vid-source" src="departmentResources.videoLink" type="getVideoType(departmentResources.videoLink)"> -->
          <source id="vid-source">
      </video>
    </div>
  </div>
  <div class="content-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 intro-text-wrap">
          <div class="intro-text">
            <h1><span>{{'HOME.introCaption' | translate}}</span></h1>
            <div class="main intro-btn"><button (click)="scrollToMain()" role="button"
                class="IE-padding"><span>{{'HOME.introButton' | translate}}</span>
                <mat-icon class="bounce">arrow_downward</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id='main' class="section no-pad transparent">
  <div class="container">
    <div class="row">
      <div class="col offset-l3 s12 l9">
        <!--div data-aos="fade-down" data-aos-easing="ease-in-sine" data-aos-duration="300" class="department-name" *ngIf="departmentResources">
          <span>{{departmentResources.name}}</span>
        </div-->
      </div>
    </div>
    <div class="row counts">
      <!-- <div class="col s12 l3">
        <mvms-image data-aos="fade-down" data-aos-easing="ease-in-sine" data-aos-duration="400" class='logo z-depth-1'
          [imageId]="departmentResources.logoId" [isAvatar]="false" *ngIf="departmentResources"></mvms-image>
      </div> -->
      <!-- <div data-aos="zoom-in" data-aos-easing="ease-in-sine" data-aos-duration="600" class="col s12 l3">
        <div *ngIf="!isSimple">
          <span class="value">{{opportunitiesCount}}</span> # {{'HOME.counter.opportunities' | translate }}
        </div>
      </div>
      <div data-aos="zoom-in" data-aos-easing="ease-in-sine" data-aos-duration="700" class="col s12 l3">
        <div *ngIf="!isSimple">
          <span class="value">{{volunteeringHoursTotalCount}}</span> # {{'HOME.counter.volunteeringHours' | translate }}
        </div>
      </div>
      <div data-aos="zoom-in" data-aos-easing="ease-in-sine" data-aos-duration="800" class="col s12 l3">
        <div *ngIf="!isSimple">
          <span class="value">{{volunteeringHoursCount}}</span> # {{'HOME.counter.volunteers' | translate }}
        </div>
      </div> -->
    </div>
    <div class="row submenu" *ngIf="!isSimple">
      <div [ngClass]="!isLoggedIn ?'col s6 l2':'col s8 l3'" >
        <a routerLink="/about-us" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"><span
            class="IE-padding">{{'HOME.subMenu.AboutUs' | translate }}</span></a>
      </div>
      <div *ngIf="!isLoggedIn" class="col s6 l2">
        <a routerLink="/join-us" routerLinkActive="active"><span
            class="IE-padding">{{'HOME.subMenu.Registration' | translate }}</span></a>
      </div>
      <div [ngClass]="!isLoggedIn ?'col s6 l2':'col s8 l3'" >
        <a routerLink="/opportunities" routerLinkActive="active"><span
            class="IE-padding">{{'HOME.subMenu.opportunities' | translate }}</span></a>
      </div>
      <div [ngClass]="!isLoggedIn ?'col s6 l2':'col s8 l3'" >
        <a routerLink="/award" routerLinkActive="active"><span
            class="IE-padding">{{'HOME.subMenu.Award' | translate }}</span></a>
      </div>
      <div *ngIf="!isLoggedIn" class="col s6 l2" >
        <a routerLink="/request-volunteers" routerLinkActive="active"><span
            class="IE-padding">{{'HOME.subMenu.RequestVolunteers' | translate }}</span></a>
      </div>
      <div [ngClass]="!isLoggedIn ?'col s6 l2':'col s8 l3'" >
        <a routerLink="/news" routerLinkActive="active"><span
            class="IE-padding">{{'HOME.subMenu.News' | translate }}</span></a>
      </div>
      <!-- mvms - 1170: saif ur rehman -->
     
    </div>
    <br>
    <router-outlet></router-outlet>

  </div>


  <div id="gallery" class="section no-pad taupe" *ngIf="!isSimple">
    <div class="container">
      <h3>{{'HOME.Gallery' | translate }}</h3>
      <div class="scroll-container">
        <owl-carousel *ngIf="galleryItems" id='maincarousel' [options]="SlideOptions" [items]="galleryItems"
          [carouselClasses]="['owl-theme', 'sliding']">
          <div class="item col l2 m4 s6" *ngFor="let item of galleryItems">
            <a [href]="BASE_URL + 'api/file/'+ item.imageId" data-lightbox='gallery' [title]="item.title">
              <img [src]="BASE_URL + 'api/file/'+ item.imageId + '/thumbnail'" alt="image"
                [attr.data-caption]="item.title" />
            </a>
          </div>

        </owl-carousel>
        <div class="owl-theme">
          <div class="owl-controls">
            <div class="custom-nav owl-nav"></div>
          </div>
        </div>
      </div>




    </div>
  </div>
<div class="section no-pad row l-flex"  *ngIf="!isSimple" style="margin-bottom: 20px; display:flex;"> 
  <div class="container" style="
    background: rgba(255, 255, 255, 0.08);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 20px;">
    <div id="map"  class="col l4 s12 SendMessage">
      <div class="content">
          <h2>Send Us a Message</h2>
          <p></p>
      </div>
       
    </div>
    <div id="contactus" class="col l8 s12">
      <form class="form dynamic-box" [formGroup]="contactUsForm" (ngSubmit)="contactUs()" #form="ngForm">
        <div class="container">
          <!-- <div class='row'>
            <h2>{{'HOME.Contact' | translate }}</h2>
          </div> -->
          <div class='row' style="margin-bottom:0px !important">
            <mat-form-field class="col s12">
              <input matInput type="text" [placeholder]="'SHARED.FormFields.FullName' | translate"
                formControlName="fromName">
              <mat-error
                *ngIf="contactUsForm.controls['fromName'].hasError('minlength') || contactUsForm.controls['fromName'].hasError('maxlength')">
                {{'SHARED.FormFields.FullName' | translate }} {{'SHARED.Errors.minMaxLength' | translate }}
              </mat-error>
              <mat-error *ngIf="contactUsForm.controls['fromName'].hasError('pattern')">
                {{'SHARED.FormFields.FullName' | translate }} {{'SHARED.Errors.lettersOnly' | translate }}
              </mat-error>
              <mat-error *ngIf="contactUsForm.controls['fromName'].hasError('required')">
                {{'SHARED.Errors.emptyField' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="row" style="margin-bottom:0px !important">
            <mat-form-field class="col s12">
              <input matInput type="email" [placeholder]="'SHARED.FormFields.Email' | translate"
                formControlName="fromEmail">
              <mat-error *ngIf="contactUsForm.controls['fromEmail'].hasError('required')">
                {{'SHARED.Errors.emptyField' | translate }}
              </mat-error>
              <mat-error *ngIf="contactUsForm.controls['fromEmail'].hasError('pattern')">
                {{'SHARED.Errors.emailInvalid' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="row" style="margin-bottom:0px !important">
            <mat-form-field class="col s12">
              <textarea matInput [placeholder]="'SHARED.FormFields.Message' | translate" type='text'
                formControlName="message"></textarea>
              <mat-hint>{{contactUsForm.controls['message'].value.length}} / 500</mat-hint>
              <mat-error *ngIf="contactUsForm.controls['message'].hasError('minlength')">
                {{'SHARED.FormFields.Message' | translate }} {{'SHARED.Errors.minLength' | translate }}
              </mat-error>
              <mat-error *ngIf="contactUsForm.controls['message'].hasError('maxlength')">
                {{'SHARED.Errors.maxLength' | translate }}
              </mat-error>
              <mat-error *ngIf="contactUsForm.controls['message'].hasError('required')">
                {{'SHARED.Errors.emptyField' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <div class="row">
            <re-captcha [siteKey]="captchaKey" formControlName="recaptchaResponse"></re-captcha>
          </div>
          <div class="row">
            <button class="IE-padding" mvmsDebounceClick (debounceClick)="form.onSubmit($event)"
              [debounceTime]="2000">{{'SHARED.Button.Send' | translate }}</button>
          </div>
          <div class="row" id="contactUsResult">
            <div class="green-text" *ngIf="contactUsSent">
              {{'SHARED.Contact.contactUsSent' | translate }}
            </div>
            <div class="red-text" *ngIf="contactUsSentError">
              {{'SHARED.Errors.contactUsSent' | translate }}
            </div>
          </div>
        </div>

      </form>
    </div>
  </div>
</div>

  <!-- <div class="section no-pad row">
    <div class="col s6 kellyogld"></div>
    <div class="col s6 greengold"></div>
  </div> -->