<div>
  <ng-progress [positionUsing]="'marginLeft'" [minimum]="0.15" [maximum]="1" [speed]="200" [showSpinner]="true"
    [direction]="'leftToRightIncreased'" [color]="'#768692'" [trickleSpeed]="250" [thick]="true" [ease]="'linear'">
  </ng-progress>
  <nav id="header" class="nav-extended gold">
    <div class="container">
      <div class="header-box ltr">
        <div class="nav-wrapper container ltr">
          <ul class="left menu ltr" >
            <li><a class="IE-padding" (click)="showLoginDialog()" *ngIf="!user"><mat-icon>account_circle</mat-icon></a></li>
           
            <li>
              <a class="IE-padding" *ngIf="user && isHome()" routerLink="dashboard/myDashboard" routerLinkActive="active"
                (click)="sidenav.opened = false">{{'DASHBOARD.navMenu.dashboard'
                  | translate }}</a>
                  <a class="IE-padding" *ngIf="user && !isHome()" routerLink="/" routerLinkActive="active" (click)="sidenav.opened = false">
                    {{'LANDING.home' | translate }}</a>
            </li>
            <li class="mobile-lang show-on-medium-and-up padding-top" style="margin-bottom:4px">
              <a class="lang" *ngFor="let lang of translate.getLangs()" href="javascript:void(0)" style="color:#3D1A69;padding: 10px; background: #FFFFFF;border-radius: 100px;"
                (click)="setLanguage(lang)" [hidden]="lang == translate.currentLang"
                [ngClass]="lang" [ngClass]="{'IE-padding-eng': lang === 'ar'}" [ngClass]="{'IE-padding-all': lang === 'en'}">{{ lang === 'en' ? 'English' : 'العربية' }}
              </a>
            </li>
            <li><a class="IE-padding" style="font-size: 15px;" (click)="showCOCDialog()" >{{'COC.Title' | translate }}</a></li>
            <li *ngIf="isCurrentLanguageArabic"><a class="IE-padding" href="https://www.ithra.com/ar/about-ithra/faqs" style="font-size: 15px;" target="_blank">{{'COC.FAQ' | translate }}</a></li>
            <li *ngIf="!isCurrentLanguageArabic"><a class="IE-padding" href="https://www.ithra.com/en/about-ithra/faqs" style="font-size: 15px;" target="_blank">{{'COC.FAQ' | translate }}</a></li>
          </ul>
        </div>

        <div class="profile hide-on-small-only" *ngIf="user">
          <div [matMenuTriggerFor]="notificationMenu" class="notification" (click)="markAknowledged()">
            <mat-icon>notifications</mat-icon>
            <span *ngIf="counter > 0" class="custom-badge">{{counter}}</span>
          </div>
          <div class="avatar">
            <mvms-image [imageId]="user.avatarId"></mvms-image>
          </div>
          <div class="user_info" fxLayout="column" fxLayoutAlign="space-around center" [ngClass]="{'IE': isCurrentLanguageArabic === true}">
            <div class="user user_name " [matMenuTriggerFor]="menu">{{(isCurrentLanguageArabic && user.arabicFullName) ? user.arabicFullName : user.fullName}}
            </div>
            <div class="user user_id">{{user.volunteerIdNo}}</div>
          </div>
        </div>

        <mat-menu #notificationMenu="matMenu" [overlapTrigger]="false" class="notificationMenu menu-expand">
          <div class="pointer" *ngFor='let notification of notifications' (click)="redirectTo(notification)">
            <div [ngStyle]="!notification.isAcknowledged && {'font-weight': 'bold'}">{{notification.title}}</div>
            <br>
            <div class="align-right">{{notification.createdDateTime | timeAgo}}</div>
            <hr>
          </div>
          <div *ngIf="notifications && notifications.length == 0">No notification found</div>
        </mat-menu>

        <mat-menu #menu="matMenu" [overlapTrigger]="false" class="profileMenu menu-expand">
          <div class="profile">
            <div class="avatar">
              <div class="photo circle">
                <mvms-image [imageId]="user.avatarId" *ngIf="user?.avatarId"></mvms-image>
                <img class="responsive-img" src="assets/images/profile.jpg" alt="avatar" *ngIf="!user?.avatarId">
              </div>
            </div>
            <div class="user IE-select">
              {{(isCurrentLanguageArabic && (!user || user.arabicFullName)) ? user?.arabicFullName : user?.fullName}}
            </div>
          </div>
          <hr />
          <div class="button-group">
            <a mat-raised-button routerLink="/dashboard" [ngClass]="{'IE-padding': isCurrentLanguageArabic === true}" class="left submit-button">{{ 'HOME.MainNavMenu.Account' |
              translate }}</a>
            <a mat-raised-button class="right submit-button" [ngClass]="{'IE-padding': isCurrentLanguageArabic === true}" (click)="logout()">{{'HOME.MainNavMenu.SignOut' |
              translate }}</a>
          </div>
        </mat-menu>

        <a *ngIf="departmentResources" [href]="'//www.ithra.com/' + translate.currentLang" class="logo">
          <mvms-image [imageId]="departmentResources.headerId"></mvms-image>
        </a>
      </div>

    </div>
  </nav>

</div>