import { Component, OnInit, OnDestroy } from '@angular/core';
import { EventListModelExtended, EventListModelConverter } from '../../events/events.model';

import { Subscription } from '../../../../../../node_modules/rxjs';
import { filter } from '../../../../../../node_modules/rxjs/operators';
import { DashboardService } from '../../dashboard.service';
import { CurrentUserService } from '../../../../services/current-user.service';
import { Router } from '@angular/router';
import { LanguageService } from '../../../../services/language.service';


@Component({
  selector: 'mvms-my-upcoming-event',
  templateUrl: './my-upcoming-event.component.html',
  styleUrls: ['./my-upcoming-event.component.scss']
})
export class MyUpcomingEventComponent implements OnInit, OnDestroy {
  public eventsList: EventListModelExtended[];
  public hasAccess: boolean;
  public queryString: string;
  public eventDays: number;
  public upcomingEventName: string;
  public upcomingEventId: string;
  isCurrentLanguageArabic: boolean;

  isLoading: boolean;

  subscription: Subscription = Subscription.EMPTY;

  constructor(protected dashboardService: DashboardService, private currentUserService: CurrentUserService, private router: Router, private languageService: LanguageService) { }

  ngOnInit() {
    this.isLoading = true;

    this.dashboardService.refreshEventList();
    this.isCurrentLanguageArabic = this.languageService.isCurrentLanguageArabic;

    this.subscription = this.dashboardService.data
      .pipe(filter(res => !!res))
      .subscribe(res => {
        if (res && res.items && res.items.length) {
          this.eventsList = res.items.map(EventListModelConverter.toEventListModelExtended);
          this.upcomingEvent();
        }
      },
        // () => this.isLoading = false
      );

      this.currentUserService.getCurrentUser().subscribe(res => {
        this.hasAccess = res.isAccepted;
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  upcomingEvent(): void {
    this.isLoading = true;
    const today: any = new Date();
    for (let i = 0; i < this.eventsList.length; i++) {
      let startDate = new Date(this.eventsList[i].startDateTime);
      console.log(this.eventsList[i]);
     if (startDate > today) {
      const timeDiff = Math.abs(today.getTime() - startDate.getTime());
      this.eventDays = Math.floor(timeDiff / (1000 * 3600 * 24));
      // this.eventDays = 1;
      this.upcomingEventName = this.eventsList[i].title;
      this.upcomingEventId = this.eventsList[i].id;
      break;
     }
    }
    this.isLoading = false;
  }
  goToEvent() {
    this.router.navigate([`/dashboard/myEvents/${this.upcomingEventId}`]);
  }

}
